import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./state";

const selectDomain = (state) => state.blocks || initialState;

export const selectBlocks = createSelector(
  [selectDomain],
  (blocksState) => blocksState.blocks
);

export const selectTerms = createSelector(
  [selectDomain],
  (blocksState) => blocksState.terms
);
export const selectReserves = createSelector(
  [selectDomain],
  (blocksState) => blocksState.reserves
);

export const selectRoadmap = createSelector(
  [selectDomain],
  (blocksState) => blocksState.roadmap
);
