// core
import React from "react";
import { useTranslation } from "react-i18next";

// components
import AppButton from "../../components/atoms/Button";

// styles
import "./index.scss";

const Page404 = () => {
  const { t } = useTranslation();

  // @ts-ignore
  return (
    <div className="page404">
      <div className="page404__container">
        <div className="page404__title"> {t("page404.title")}</div>
        <div className="page404__text">{t("page404.text")}</div>
        <AppButton
          className="app-button app-button--fill page404__btn"
          to={"/"}
        >
          {t("page404.btn")}
        </AppButton>
      </div>
    </div>
  );
};

export default Page404;
