// core
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
// store
import { getBlocks } from "../../store/modules/blocks/services";
import { getGeneralData } from "../../store/modules/general-data/services";
import { getNews } from "../../store/modules/news/services";
import { selectBlocks } from "../../store/modules/blocks/selectors";
import { selectGeneralData } from "../../store/modules/general-data/selectors";
import { selectVariables } from "../../store/modules/variables/selectors";
import { selectNews } from "../../store/modules/news/selectors";

// utils

// components
import PageLoader from "../../components/atoms/PageLoader";
import HomeMain from "./HomeMain";
import HomeAbout from "./HomeAbout";
import HomeHow from "./HomeHow";
import HomeCta from "./HomeCta";
import HomeActivity from "./HomeActivity";
import HomeFinished from "./HomeFinished";
import HomePartners from "./HomePartners";
import HomeWorld from "./HomeWorld";
import HomeFaqs from "./HomeFaqs";
import NewsSlider from "../../components/atoms/NewsSlider";

// styles
import "./index.scss";

import AOS from "aos";

const Home = () => {
  const { t } = useTranslation();
  // store
  const dispatch = useDispatch();
  const blocks = useSelector(selectBlocks);
  const generalData = useSelector(selectGeneralData);
  const variables = useSelector(selectVariables);
  const news = useSelector(selectNews);

  const location = useLocation();

  useEffect(() => {
    dispatch(getBlocks());
    dispatch(getGeneralData());
    // dispatch(getRoadmap());
    dispatch(getNews({ per_page: 10, page: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      blocks.isSuccess &&
      generalData.isSuccess &&
      variables.isSuccess &&
      news.isSuccess
    ) {
      AOS.init({
        offset: 200,
        duration: 1000,
        easing: "ease-out-cubic",
        //     offset: 200,
        //     duration: 600,
        //     easing: "ease-out-cubic",
      });

      if (location.hash) {
        let anchor = location.hash.slice(1);
        let elem = document.getElementById(anchor);

        setTimeout(() => {
          if (elem) {
            window.scrollTo({ top: elem.offsetTop - 50, behavior: "smooth" });
          }
        }, 300);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    blocks.isSuccess,
    generalData.isSuccess,
    variables.isSuccess,
    news.isSuccess,
  ]);

  // @ts-ignore
  return (
    <div className="home">
      {blocks.isSuccess &&
      generalData.isSuccess &&
      variables.isSuccess &&
      news.isSuccess ? (
        <>
          <HomeMain data={blocks.data.banner} />
          <HomeHow data={blocks.data.how_to_participate} />
          <HomeCta data={blocks.data.cta} />
          {generalData.data.activities?.active && (
            <HomeActivity
              active={generalData.data.activities.active}
              future={generalData.data.activities.future}
            />
          )}
          {generalData.data.activities?.ended?.length ? (
            <HomeFinished data={generalData.data.activities?.ended} />
          ) : (
            <></>
          )}
          <HomePartners data={generalData.data.partners} />
          <HomeAbout
            data={blocks.data.about_token}
            variables={variables.data}
          />
          {/*<HomeWorld variables={variables.data} />*/}
          {news.data?.length > 0 && (
            <div className="home__slider">
              <div className="app__wrapper">
                <NewsSlider title={t("home.sliderTitle")} data={news.data} />
              </div>
            </div>
          )}
          <HomeFaqs
            data={blocks.data.faq}
            generalData={generalData.data.faqs}
          />
        </>
      ) : (
        <PageLoader active={true} />
      )}
    </div>
  );
};

export default Home;
