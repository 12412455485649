import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./state";

const selectDomain = (state) => state.contact || initialState;

export const selectContact = createSelector(
  [selectDomain],
  (state) => state.contact
);
