// core
import React from "react";
import { Link as RouterLink } from "react-router-dom";

// components
import Logo from "../../../images/Logo";
import Close from "../../../icons/Close";
import AppButton from "../../Button";
import HeaderNav from "../HeaderNav";

// styles
import "./index.scss";

const Sidebar = ({ isActive, onClose, onOpenModal }) => {
  const onCloseHandler = () => {
    onClose();
  };

  return (
    <div className={"sidebar" + (isActive ? " sidebar--active" : "")}>
      <div className="sidebar__overlay" onClick={onCloseHandler} />
      <aside className="sidebar__aside">
        <div className="sidebar__head">
          <div className="sidebar__logo">
            <RouterLink to={"/"}>
              <Logo />
            </RouterLink>
          </div>

          <AppButton
            className="sidebar__close app-button--flat"
            onClick={onCloseHandler}
          >
            <Close />
          </AppButton>
        </div>
        <div className="sidebar__body">
          <div className="sidebar__content">
            <HeaderNav onClick={onCloseHandler} />
          </div>
          <div className="sidebar__footer">
            <div className="sidebar__actions">
              <AppButton
                className="sidebar__btn app-button--fill"
                size={"big"}
                onClick={() => onOpenModal()}
              >
                Зв’язатись
              </AppButton>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
