import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import * as services from "./services";

// Creating slice with reducers
const slice = createSlice({
  name: "variables",
  initialState,
  extraReducers: {
    [services.getVariables.pending]: (state) => {
      state.variables.isLoading = true;
      state.variables.isSuccess = false;
      state.variables.error = null;
    },
    [services.getVariables.rejected]: (state, action) => {
      state.variables.isLoading = false;
      state.variables.error = action.payload;
    },
    [services.getVariables.fulfilled]: (state, action) => {
      state.variables.isLoading = false;
      state.variables.isSuccess = true;

      state.variables.data = action?.payload?.data?.data?.length
        ? action.payload.data.data.reduce((acc, item) => {
            return { ...acc, [item.key]: item };
          }, {})
        : null;
    },
  },
});

export default slice.reducer;
