// core
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";

// utils

// components

// styles
import "./index.scss";
import AppButton from "../../../components/atoms/Button";
import ActivityModal from "../../../components/features/ActivityModal";

const HomeFinished = ({ active, future }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [isActiveModal, setIsActiveModal] = useState(false);

  const openModalHandler = () => {
    setIsActiveModal(true);
  };

  const closeModalHandler = () => {
    setTimeout(() => setIsActiveModal(false), 400);
  };

  return (
    <div className="home-activity" id="activity">
      <div className="app__wrapper">
        <div className="home-activity__wrap">
          <div className="home-activity__title">
            {t("landing.activitiesTitle1")}{" "}
            <span>{t("landing.activitiesTitle2")}</span>{" "}
            {t("landing.activitiesTitle3")}
          </div>
          <div className="home-activity__content">
            <div className="home-activity__content-left">
              <div className="home-activity__content-left__item">
                <div className="home-activity__content-left__item-connector">
                  <div className="home-activity__content-left__item-head">
                    <div className="home-activity__content-left__item-head-left">
                      <div className="home-activity__content-left__item-head-left-date">
                        {active.start_date && active.end_date ? (
                          <>
                            {active.start_date} – {active.end_date}
                          </>
                        ) : (
                          t("landing.soon")
                        )}
                      </div>
                      <div className="home-activity__content-left__item-head-left-title">
                        {active.title}
                      </div>
                    </div>
                    {width < 768 && (
                      <div
                        className="home-activity__content-left__item-desc"
                        dangerouslySetInnerHTML={{
                          __html: active.short_description,
                        }}
                      />
                    )}
                    <div className="home-activity__content-left__item-head-right">
                      <AppButton
                        onClick={openModalHandler}
                        size={width > 1024 ? "big" : ""}
                        className="app-button--fill"
                      >
                        {t("landing.activitiesMore")}
                      </AppButton>
                      {/*<a href="/terms">{t("landing.termsOfUse")}</a>*/}
                    </div>
                  </div>
                  {width > 768 && (
                    <div
                      className="home-activity__content-left__item-desc"
                      dangerouslySetInnerHTML={{
                        __html: active.short_description,
                      }}
                    />
                  )}
                </div>
                <div className="home-activity__content-left__item-image">
                  <img src={active.image} alt={active.title} />
                </div>
              </div>
            </div>
            <div className="home-activity__content-right">
              {future.map((el) => {
                return (
                  <div
                    className="home-activity__content-right__item"
                    key={el.id}
                  >
                    <div className="home-activity__content-right__item-text">
                      <div className="home-activity__content-right__item-text__title">
                        {el.title}
                      </div>
                      <div
                        className="home-activity__content-right__item-text__desc"
                        dangerouslySetInnerHTML={{
                          __html: el.short_description,
                        }}
                      />
                    </div>
                    <div className="home-activity__content-right__item-status">
                      {el.start_date && el.end_date ? (
                        <>
                          {el.start_date} – {el.end_date}
                        </>
                      ) : (
                        t("landing.soon")
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {isActiveModal &&
        ReactDOM.createPortal(
          <ActivityModal
            isActive={isActiveModal}
            data={active}
            onClose={closeModalHandler}
          />,
          document.getElementById("modal")
        )}
    </div>
  );
};

export default HomeFinished;
