// core
import React from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link as RouterLink } from "react-router-dom";
import { useWindowSize } from "react-use";
// components

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// styles
import "./index.scss";

const NewsSlider = ({ data, title }) => {
  const { width } = useWindowSize();
  const getNavigationStatus = () => {
    let status = false;
    let slidesCount = data.length;
    if (
      (width > 1024 && slidesCount > 5) ||
      (width > 768 && width < 1023 && slidesCount > 4) ||
      (width > 640 && width < 767 && slidesCount > 3) ||
      (width > 480 && width < 640 && slidesCount > 2) ||
      (width < 479 && slidesCount > 1)
    ) {
      status = true;
    }

    return status;
  };

  return (
    <div className="news-slider">
      <div className="news-slider__title">{title}</div>
      <div className="news-slider__content">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={16}
          slidesPerView={1}
          slidesPerGroup={1}
          navigation={getNavigationStatus()}
          // loop={true}
          pagination={{ clickable: true }}
          breakpoints={{
            480: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            640: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
            768: {
              slidesPerView: 4,
              slidesPerGroup: 4,
            },
            1024: {
              slidesPerView: 5,
              slidesPerGroup: 5,
            },
          }}
        >
          {data.map((item) => (
            <SwiperSlide key={item.id}>
              <RouterLink
                to={`${item.url}`}
                target={"_blank"}
                key={item.id}
                className="news-slider__card"
              >
                <div className="news-slider__card-img">
                  <img src={item.image} alt={item.title} />
                </div>

                <div className="news-slider__card-title">{item.title}</div>
              </RouterLink>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default NewsSlider;
