import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";

// Creating slice with reducers
const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleContactUsModalStatus: (state, action) => {
      state.contactUsModal.isActive = action.payload;
    },
  },
});

export const { toggleContactUsModalStatus } = slice.actions;
export default slice.reducer;
