import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import * as services from "./services";

// Creating slice with reducers
const slice = createSlice({
  name: "generalData",
  initialState,
  extraReducers: {
    [services.getGeneralData.pending]: (state) => {
      state.generalData.isLoading = true;
      state.generalData.isSuccess = false;
      state.generalData.error = null;
    },
    [services.getGeneralData.rejected]: (state, action) => {
      state.generalData.isLoading = false;
      state.generalData.error = action.payload;
    },
    [services.getGeneralData.fulfilled]: (state, action) => {
      state.generalData.isLoading = false;
      state.generalData.isSuccess = true;
      state.generalData.data = action.payload.data.data;
    },
  },
});

export default slice.reducer;
