// core
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";

// services

// components
import Close from "../../icons/Close";
import HowDUA from "../../images/HowDUA";
import HowDEN from "../../images/HowDEN";
import HowMUA from "../../images/HowMUA";
import HowMEN from "../../images/HowMEN";

// styles
import "./index.scss";

const HowModal = ({ isActive, data, onClose }) => {
  const { i18n } = useTranslation();
  const { width } = useWindowSize();

  const [isLocalActive, setLocalActive] = useState(false);

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        // @ts-ignore
        document.querySelector("body").classList.add("body--fixed");
        setLocalActive(true);
      }, 100);
    }
  }, [isActive]);

  const onCloseHandler = () => {
    setLocalActive(false);
    setTimeout(() => {
      // @ts-ignore
      document.querySelector("body").classList.remove("body--fixed");
    }, 300);
    onClose();
  };

  const getSchema = () => {
    let data = null;
    const localization =
      i18n.language && i18n.language.length > 2
        ? i18n.language.slice(0, 2)
        : i18n.language;

    if (width > 768) {
      data = localization === "en" ? <HowDEN /> : <HowDUA />;
    } else {
      data = localization === "en" ? <HowMEN /> : <HowMUA />;
    }
    return data;
  };

  return (
    <div className={"app-modal" + (isLocalActive ? " app-modal--active" : "")}>
      <div className="app-modal__overlay" onClick={onCloseHandler} />
      <div className="app-modal__wrapper how-modal">
        <div className="app-modal__close" onClick={onCloseHandler}>
          <Close />
        </div>
        <div className="app-modal__content-scroll">
          <div className="how-modal__content">
            <div className="how-modal__title">{data.main_title}</div>
            <div className="how-modal__schema">{getSchema()}</div>
            <div className="how-modal__info">
              <div className="how-modal__info-title">{data.title}</div>
              <div
                className="how-modal__info-text"
                dangerouslySetInnerHTML={{
                  __html: data.subtitle,
                }}
              />
              <div className="how-modal__info-grid">
                <div className="how-modal__info-column">
                  <div className="how-modal__info-subtitle">
                    {data.left_title}
                  </div>
                  <div
                    className="how-modal__info-text"
                    dangerouslySetInnerHTML={{
                      __html: data.left_description,
                    }}
                  />
                </div>
                <div className="how-modal__info-column">
                  <div className="how-modal__info-subtitle">
                    {data.right_title}
                  </div>
                  <div
                    className="how-modal__info-text"
                    dangerouslySetInnerHTML={{
                      __html: data.right_description,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowModal;
