import axios from "axios";
import { getLocaleKey } from "./../locales/i18n";

const configApiClient = {
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
    accept: "application/json",
    "content-type": "application/json",
    "X-localization": getLocaleKey(),
  },
  withCredentials: false,
};

const $apiClient = axios.create(configApiClient);

export { $apiClient };
