import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import * as services from "./services";
import { getTerms } from "./services";

// Creating slice with reducers
const slice = createSlice({
  name: "blocks",
  initialState,
  extraReducers: {
    [services.getBlocks.pending]: (state) => {
      state.blocks.isLoading = true;
      state.blocks.isSuccess = false;
      state.blocks.error = null;
    },
    [services.getBlocks.rejected]: (state, action) => {
      state.blocks.isLoading = false;
      state.blocks.error = action.payload;
    },
    [services.getBlocks.fulfilled]: (state, action) => {
      state.blocks.isLoading = false;
      state.blocks.isSuccess = true;
      state.blocks.data = action?.payload?.data?.data?.blocks;
    },

    [services.getTerms.pending]: (state) => {
      state.terms.isLoading = true;
      state.terms.isSuccess = false;
      state.terms.error = null;
    },
    [services.getTerms.rejected]: (state, action) => {
      state.terms.isLoading = false;
      state.terms.error = action.payload;
    },
    [services.getTerms.fulfilled]: (state, action) => {
      state.terms.isLoading = false;
      state.terms.isSuccess = true;
      state.terms.data = action?.payload?.data?.data;
    },

    [services.getReserves.pending]: (state) => {
      state.reserves.isLoading = true;
      state.reserves.isSuccess = false;
      state.reserves.error = null;
    },
    [services.getReserves.rejected]: (state, action) => {
      state.reserves.isLoading = false;
      state.reserves.error = action.payload;
    },
    [services.getReserves.fulfilled]: (state, action) => {
      state.reserves.isLoading = false;
      state.reserves.isSuccess = true;
      state.reserves.data = action?.payload?.data?.data;
    },

    [services.getRoadmap.pending]: (state) => {
      state.roadmap.isLoading = true;
      state.roadmap.isSuccess = false;
      state.roadmap.error = null;
    },
    [services.getRoadmap.rejected]: (state, action) => {
      state.roadmap.isLoading = false;
      state.roadmap.error = action.payload;
    },
    [services.getRoadmap.fulfilled]: (state, action) => {
      state.roadmap.isLoading = false;
      state.roadmap.isSuccess = true;
      state.roadmap.data = action?.payload?.data?.data;
    },
  },
});

export default slice.reducer;
