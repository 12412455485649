// core
import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik, Field, Form } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

// store
import { sendContact } from "../../../store/modules/contact/services";
import { selectContact } from "../../../store/modules/contact/selectors";

// components
import AppButton from "../../atoms/Button";

// styles
import "./index.scss";

// data
import { INITIAL_DATA } from "./data";

const ContactUsModal = ({ isActive, onClose }) => {
  // store
  const dispatch = useDispatch();
  const contact = useSelector(selectContact);

  const { t } = useTranslation();
  const [isLocalActive, setLocalActive] = useState(false);
  const [isTrySend, setIsTrySend] = useState(false);
  const [modalState, setModalState] = useState("form"); //form | message
  const [message, setMessage] = useState(t("modal.contactUs.success")); //form | message

  useEffect(() => {
    if (isActive) {
      setTimeout(() => {
        // @ts-ignore
        document.querySelector("body").classList.add("body--fixed");
        setLocalActive(true);
      }, 100);
    }
  }, [isActive]);

  useEffect(() => {
    if (!contact.isLoading && isTrySend) {
      setModalState("message");
      if (contact.isSuccess) {
        setMessage(t("modal.contactUs.success"));
      } else {
        setMessage(t("modal.contactUs.error"));
      }
    }
  }, [contact.isLoading]);

  const PHONE_NO_REGEX = /^[0-9\-\(\)\+ ]{8,16}$/;

  const ModalValidation = Yup.object().shape({
    phone: Yup.string()
      .matches(PHONE_NO_REGEX, {
        message: t("validation.isPhone"),
        excludeEmptyString: true,
      })
      .min(8, t("validation.min"))
      .max(16, t("validation.max"))
      .required(t("validation.required")),
    email: Yup.string()
      .email(t("validation.isEmail"))
      .required(t("validation.required")),
  });

  const onCloseHandler = () => {
    setLocalActive(false);
    setTimeout(() => {
      setModalState("form");
      setMessage(t("modal.contactUs.success"));
      // @ts-ignore
      document.querySelector("body").classList.remove("body--fixed");
    }, 300);
    onClose();
  };

  const onSendHandler = (payload) => {
    setIsTrySend(true);
    dispatch(sendContact(payload));
  };

  return (
    <div className={"app-modal" + (isLocalActive ? " app-modal--active" : "")}>
      <div className="app-modal__overlay" onClick={onCloseHandler} />
      <div className="app-modal__wrapper contact-us-modal">
        <div className="app-modal__content">
          {modalState === "form" && (
            <div className="contact-us-modal__form">
              <div className="contact-us-modal__title">
                {t("modal.contactUs.title")}
              </div>

              <Formik
                enableReinitialize={true}
                initialValues={INITIAL_DATA}
                onSubmit={onSendHandler}
                validationSchema={ModalValidation}
              >
                {({ errors, touched }) => (
                  <Form className="app-form">
                    <div className="app-form__field app-form__field--input contact-us-modal__field">
                      <label> {t("modal.contactUs.inputPhoneLabel")}</label>
                      <Field
                        type="text"
                        name="phone"
                        placeholder={t("modal.contactUs.inputPhonePlaceholder")}
                        autoComplete="chrome-off"
                        className={
                          errors.phone &&
                          touched.phone &&
                          "app-form__field--error"
                        }
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="app-form__field-error"
                      />
                    </div>
                    <div className="app-form__field app-form__field--input contact-us-modal__field">
                      <label>{t("modal.contactUs.inputEmailLabel")}</label>
                      <Field
                        type="text"
                        name="email"
                        placeholder={t("modal.contactUs.inputEmailPlaceholder")}
                        autoComplete="chrome-off"
                        className={
                          errors.email &&
                          touched.email &&
                          "app-form__field--error"
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="app-form__field-error"
                      />
                    </div>

                    <div className="contact-us-modal__actions">
                      <AppButton
                        onClick={onCloseHandler}
                        size={"big"}
                        className="contact-us-modal__btn app-button--outline"
                      >
                        {t("actions.close")}
                      </AppButton>
                      <AppButton
                        disabled={
                          contact.isLoading || Object.keys(errors).length
                        }
                        type={"submit"}
                        loading={contact.isLoading}
                        size={"big"}
                        className="contact-us-modal__btn app-button--fill"
                      >
                        {t("actions.contacts")}
                      </AppButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
          {modalState === "message" && (
            <div className="contact-us-modal__message">
              <div
                className="contact-us-modal__message-text"
                dangerouslySetInnerHTML={{ __html: message }}
              />
              <div className="contact-us-modal__actions">
                <AppButton
                  onClick={onCloseHandler}
                  className="contact-us-modal__btn app-button--fill"
                >
                  Закрити
                </AppButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactUsModal;
