// core
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Field, Form } from "formik";
import Select from "react-select";
// utils

// components
import Logo from "../../../components/icons/Logo";
// styles
import "./index.scss";
import AppButton from "../../../components/atoms/Button";
import { numberFormatter } from "../../../utils/common";

const HomeHow = ({ data }) => {
  const { t } = useTranslation();
  const [deposit, setDeposit] = useState(1000);
  const [period, setPeriod] = useState(1);
  const options = [
    { value: 1, label: t("landing.profitDay") },
    { value: 7, label: t("landing.profitWeek") },
    { value: 30, label: t("landing.profitMonth") },
  ];
  return (
    <div className="home-how">
      <div className="app__wrapper">
        <div className="home-how__wrap">
          <div className="home-how__title">{t("landing.howTitle")}</div>
          <div className="home-how__content">
            <div className="home-how__content-left">
              <div className="home-how__content-left__item">
                <div className="home-how__content-left__item-connector">
                  <div className="home-how__content-left__item-text">
                    <div className="home-how__content-left__item-step">
                      {t("landing.howStep1")}
                    </div>
                    <div className="home-how__content-left__item-title">
                      {data.step_1_title}
                    </div>
                    <div
                      className="home-how__content-left__item-desc"
                      dangerouslySetInnerHTML={{
                        __html: data.step_1_description,
                      }}
                    />
                  </div>
                  <div
                    id="claimrBlock"
                    className="home-how__content-left__item-btn"
                  >
                    {/*<AppButton*/}
                    {/*  className="app-button--fill"*/}
                    {/*  href={data.social_url}*/}
                    {/*  target="_blank"*/}
                    {/*>*/}
                    {/*  {t("landing.howButton")}*/}
                    {/*</AppButton>*/}
                    {/*<AppButton*/}
                    {/*  className="app-button--fill"*/}
                    {/*  href={data.social_url_2}*/}
                    {/*  target="_blank"*/}
                    {/*>*/}
                    {/*  {t("landing.howButton2")}*/}
                    {/*</AppButton>*/}
                    {/*<AppButton*/}
                    {/*  className="app-button--fill"*/}
                    {/*  href={data.connect_wallet_url}*/}
                    {/*  target="_blank"*/}
                    {/*>*/}
                    {/*  {t("landing.howButton3")}*/}
                    {/*</AppButton>*/}
                  </div>
                </div>
              </div>
              <div className="home-how__content-left__item">
                <div className="home-how__content-left__item-step">
                  {t("landing.howStep2")}
                </div>
                <div className="home-how__content-left__item-title">
                  {data.step_2_title}
                </div>
                <div
                  className="home-how__content-left__item-desc"
                  dangerouslySetInnerHTML={{
                    __html: data.step_2_description,
                  }}
                />
              </div>
            </div>
            <div className="home-how__content-right">
              <div className="home-how__content-right__item">
                <div className="home-how__content-right__item-title">
                  {t("landing.howCalculator")}
                </div>
                <div className="home-how__content-right__item-input">
                  <Formik
                    initialValues={{
                      numberInput: 1000,
                      rangeInput: 1000,
                      selectInput: 1,
                    }}
                    onSubmit={(values) => {
                      console.log(values);
                    }}
                  >
                    {({ values, setFieldValue }) => (
                      <Form>
                        <label htmlFor="numberInput">
                          <span>{t("landing.howSum")}</span>
                          <span className="home-how__content-right__item-input-container">
                            <Field
                              className="home-how__content-right__item-input-input"
                              onBlur={(e) => {
                                let newValue = Number(e.target.value);
                                if (newValue < 1000) {
                                  newValue = 1000;
                                } else if (newValue > 1000000) {
                                  newValue = 1000000;
                                }
                                setFieldValue("numberInput", newValue);
                                setFieldValue("rangeInput", newValue);
                                setDeposit(newValue);
                              }}
                              maxLength={7}
                              type="number"
                              name="numberInput"
                              min={1000}
                              max={1000000}
                              step={500}
                              onChange={(e) => {
                                let newValue = e.target.value;
                                // Ensure no more than 7 characters are entered
                                if (newValue.length > 7) {
                                  newValue = newValue.slice(0, 7);
                                }
                                setFieldValue(
                                  "numberInput",
                                  parseInt(newValue)
                                );
                                setFieldValue("rangeInput", parseInt(newValue));
                                setDeposit(newValue);
                              }}
                            />
                            <Field
                              className="home-how__content-right__item-input-range"
                              type="range"
                              name="rangeInput"
                              min={1000}
                              max={1000000}
                              step={500}
                              onChange={(e) => {
                                setFieldValue(
                                  "numberInput",
                                  parseInt(e.target.value)
                                );
                                setFieldValue(
                                  "rangeInput",
                                  parseInt(e.target.value)
                                );
                                setDeposit(e.target.value);
                              }}
                            />
                          </span>
                        </label>
                        <label htmlFor="numberInput">
                          <span>{t("landing.howTerm")}</span>
                          <Field
                            name="selectInput"
                            onChange={(e) => {
                              setFieldValue("selectInput", parseFloat(e.value));
                              setPeriod(e.value);
                            }}
                            component={Select}
                            options={options}
                            defaultValue={options[0]}
                          ></Field>
                        </label>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className="home-how__content-right__item-card">
                  <div className="home-how__content-right__item-card-title">
                    {t("landing.howProfit")}
                  </div>
                  <div className="home-how__content-right__item-card-value">
                    <span>
                      {numberFormatter(((deposit * 0.2) / 365) * period)}
                    </span>
                    <Logo />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeHow;
