import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import * as services from "./services";

// Creating slice with reducers
const slice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers: {
    [services.sendContact.pending]: (state) => {
      state.contact.isLoading = true;
      state.contact.isSuccess = false;
      state.contact.error = null;
    },
    [services.sendContact.rejected]: (state, action) => {
      state.contact.isLoading = false;
      state.contact.error = action.payload;
    },
    [services.sendContact.fulfilled]: (state) => {
      state.contact.isLoading = false;
      state.contact.isSuccess = true;
    },
  },
});

export default slice.reducer;
