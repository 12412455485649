// core
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
// utils

// components
import ArrowUpRight from "./../../../components/icons/ArrowUpRight";
import ChevronRight from "./../../../components/icons/ChevronRight";
import AppButton from "../../../components/atoms/Button";
import HowModal from "../../../components/features/HowModal";
import WorldUa from "./../../../assets/images/world_ua.png";
import WorldEn from "./../../../assets/images/world_en.png";

// styles
import "./index.scss";

const HomeAbout = ({ data, variables }) => {
  const { t, i18n } = useTranslation();
  const { width } = useWindowSize();
  const [isActiveModal, setIsActiveModal] = useState(false);
  const openModalHandler = () => {
    setIsActiveModal(true);
  };

  const closeModalHandler = () => {
    setTimeout(() => setIsActiveModal(false), 400);
  };
  return (
    <div className="home-about" id="about">
      <div className="home-about__bg home-about__bg--1" />
      <div className="home-about__bg home-about__bg--2" />
      <div className="app__wrapper">
        <div className="home-about__grid">
          <div className="home-about__content">
            {width > 768 && <h2 className="home-about__title">{data.title}</h2>}

            <div className="home-about__content-container">
              <div className="home-about__box">
                {width <= 768 && (
                  <h2 className="home-about__title">{data.title}</h2>
                )}

                <div
                  className="home-about__box-text"
                  dangerouslySetInnerHTML={{
                    __html: data.description,
                  }}
                />

                {variables.whitepaper_about &&
                  variables.whitepaper_about.value && (
                    <div>
                      <a
                        href={variables.whitepaper_about.value}
                        className="home-about__box-link"
                        title={t("aboutToken.whitepaper")}
                        target="_blank"
                      >
                        {t("aboutToken.whitepaper")} <ArrowUpRight />
                      </a>
                    </div>
                  )}

                {variables.documents && variables.documents.value && (
                  <div>
                    <a
                      href={variables.documents.value}
                      className="home-about__box-link"
                      title={t("footer.documents")}
                      target="_blank"
                    >
                      {t("footer.documents")} <ArrowUpRight />
                    </a>
                  </div>
                )}
                <AppButton
                  className="app-button app-button--fill home-about__box-btn"
                  size={"big"}
                  append={<ChevronRight />}
                  onClick={() => {
                    openModalHandler();
                  }}
                >
                  {t("actions.details")}
                </AppButton>
              </div>
              <div className="home-about__points">
                <img src={i18n.language === "uk" ? WorldUa : WorldEn} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {isActiveModal &&
        ReactDOM.createPortal(
          <HowModal
            isActive={isActiveModal}
            data={data.popup}
            onClose={closeModalHandler}
          />,
          document.getElementById("modal")
        )}
    </div>
  );
};

export default HomeAbout;
