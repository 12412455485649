// core
import React from "react";
import { useTranslation } from "react-i18next";

// utils

// components

// styles
import "./index.scss";

const HomePartners = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="home-partners">
      <div className="app__wrapper">
        <div className="home-partners__wrap">
          <div className="home-partners__title">
            {t("landing.partnersTitle")}
          </div>
          <div className="home-partners__item-wrapper">
            {data.map((el) => {
              return (
                <div className="home-partners__item-wrap" key={el.id}>
                  <a
                    href={el.url}
                    target={"_blank"}
                    className="home-partners__item"
                  >
                    <img src={el.image} />
                  </a>
                </div>
              );
            })}
          </div>
          <div className="home-partners__caption">
            {t("landing.partnerCaption")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePartners;
