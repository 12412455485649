// core
import React from "react";

// components
import Logo from "../../../components/images/Logo";

// styles
import "./index.scss";

const Loader = ({ active }) => {
  return (
    <div className={"loader" + (active ? " loader--active" : "")}>
      <div className="loader__content">
        <Logo />
      </div>
    </div>
  );
};

export default Loader;
