// core
import { Link as RouterLink } from "react-router-dom";

// components

// styles
import "./index.scss";

const AppButton = ({
  type,
  children,
  onClick,
  className,
  disabled,
  size,
  prepend,
  append,
  href,
  target,
  to,
  loading,
}) => {
  return to ? (
    <RouterLink
      to={to}
      disabled={!!disabled}
      className={
        "app-button" +
        (size && size === "small" ? " app-button--small" : "") +
        (size && size === "big" ? " app-button--big" : "") +
        (className ? ` ${className}` : "")
      }
    >
      <div className="app-button__content">
        {loading ? (
          <>
            <span className="app-button__loader" />
          </>
        ) : (
          <>
            {prepend && <div className="app-button-prepend">{prepend}</div>}
            {children}
            {append && <div className="app-button-append">{append}</div>}
          </>
        )}
      </div>
    </RouterLink>
  ) : href ? (
    <a
      href={href}
      disabled={!!disabled}
      target={target ? target : "_self"}
      className={
        "app-button" +
        (size && size === "small" ? " app-button--small" : "") +
        (size && size === "big" ? " app-button--big" : "") +
        (className ? ` ${className}` : "")
      }
    >
      <div className="app-button__content">
        {loading ? (
          <>
            <span className="app-button__loader" />
          </>
        ) : (
          <>
            {prepend && <div className="app-button-prepend">{prepend}</div>}
            {children}
            {append && <div className="app-button-append">{append}</div>}
          </>
        )}
      </div>
    </a>
  ) : (
    <button
      type={type ? type : "button"}
      onClick={onClick}
      disabled={!!disabled}
      className={
        "app-button" +
        (size && size === "small" ? " app-button--small" : "") +
        (size && size === "big" ? " app-button--big" : "") +
        (className ? ` ${className}` : "")
      }
    >
      <div className="app-button__content">
        {loading ? (
          <>
            <span className="app-button__loader" />
          </>
        ) : (
          <>
            {prepend && <div className="app-button-prepend">{prepend}</div>}
            {children}
            {append && <div className="app-button-append">{append}</div>}
          </>
        )}
      </div>
    </button>
  );
};

export default AppButton;
