// core
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";

// utils

// components

// styles
import "./index.scss";
import AppButton from "../../../components/atoms/Button";
import ActivityModal from "../../../components/features/ActivityModal";

const HomeFinished = ({ data }) => {
  const { t } = useTranslation();
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [activeActivity, setActiveActivity] = useState(null);
  const openModalHandler = (data) => {
    console.log(data);
    setActiveActivity(data);
    setIsActiveModal(true);
  };

  const closeModalHandler = () => {
    setTimeout(() => {
      setIsActiveModal(false);
      setActiveActivity(null);
    }, 400);
  };
  return (
    <div className="home-finished">
      <div className="app__wrapper">
        <div className="home-finished__wrap">
          <div className="home-finished__title">
            {t("landing.endedActivities")}
          </div>
          <div className="home-finished__item-wrapper">
            {data.map((el) => {
              return (
                <div className="home-finished__item-wrap" key={el.id}>
                  <div className="home-finished__item">
                    <div className="home-finished__item-title">{el.title}</div>
                    <AppButton
                      className="app-button--fill"
                      onClick={() => {
                        openModalHandler(el);
                      }}
                    >
                      {t("landing.results")}
                    </AppButton>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {isActiveModal &&
        ReactDOM.createPortal(
          <ActivityModal
            isActive={isActiveModal}
            data={activeActivity}
            onClose={closeModalHandler}
          />,
          document.getElementById("modal")
        )}
    </div>
  );
};

export default HomeFinished;
