// core
import { useState } from "react";
import { useTranslation } from "react-i18next";

// components
import Expander from "../../../components/atoms/Expander";

// styles
import "./index.scss";
import ArrowUpRight from "../../../components/icons/ArrowUpRight";

const HomeFaqs = ({ data, generalData }) => {
  const { t } = useTranslation();
  const [expanderId, setExpanderId] = useState(null);

  const onClickHandler = (id) => {
    setExpanderId(expanderId !== id ? id : null);
  };

  return (
    <div className="home-faqs" id="faq">
      <div className="app__wrapper">
        <div className="home-faqs__grid">
          <div className="home-faqs__aside">
            <div className="home-faqs__title">{data.title}</div>
            <div
              className="home-faqs__text"
              dangerouslySetInnerHTML={{
                __html: t("landing.faqsDescription"),
              }}
            />
            <a
              href={data.url}
              className="home-faqs__link"
              target="_blank"
              title={t("faq.linkTitle")}
            >
              {t("faq.linkTitle")}
              <ArrowUpRight />
            </a>
          </div>
          <div className="home-faqs__content">
            <div className="home-faqs__expander">
              {generalData.map((item, index) => (
                <div
                  data-aos="fade-up"
                  data-aos-offset="150"
                  data-aos-delay={(index + 1) * 200}
                  className="home-faqs__expander-item"
                  key={index}
                  id={`item-${index}`}
                >
                  <Expander
                    title={item.question}
                    active={expanderId === index}
                    onClick={() => onClickHandler(index)}
                  >
                    <div
                      className="home-faqs__expander-content"
                      dangerouslySetInnerHTML={{
                        __html: item.answer,
                      }}
                    />
                  </Expander>
                </div>
              ))}
            </div>
          </div>
          <div className="home-faqs__polygon">
            <svg
              width="45"
              height="46"
              viewBox="0 0 45 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.4225 0.528266C26.7801 0.0819666 28.2727 0.393832 29.338 1.34639L43.413 13.9318C44.4783 14.8844 44.9545 16.3329 44.6622 17.7317L40.8004 36.2138C40.5082 37.6126 39.4918 38.7493 38.1342 39.1956L20.1974 45.0922C18.8398 45.5385 17.3473 45.2267 16.282 44.2741L2.20691 31.6887C1.14161 30.7361 0.665414 29.2876 0.9577 27.8888L4.81947 9.40671C5.11176 8.00786 6.12812 6.8712 7.4857 6.4249L25.4225 0.528266Z"
                fill="#FDEAE2"
              />
            </svg>
          </div>
        </div>
        <div
          className="home-faqs__caption"
          data-aos="fade-up"
          data-aos-offset="150"
        >
          {t("landing.landingCaption")}
        </div>
      </div>
    </div>
  );
};

export default HomeFaqs;
