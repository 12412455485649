import { useEffect } from "react";
import { DEFAULT_LOCALE, languagesList } from "../locales/data";
import { useLocation, useNavigate } from "react-router-dom";

// iOS treats only certain elements as clickable. Add a listener on the React root
export default function useSetUrlLang(currentLocale) {
  const { pathname, search, hash } = useLocation();

  const navigate = useNavigate();
  const locales = languagesList.map((lang) => lang.id);

  useEffect(() => {
    if (currentLocale === DEFAULT_LOCALE) {
      let isWrongPath = locales.some(
        (locale) =>
          pathname.slice(0, 3) === `/${locale}` && locale !== DEFAULT_LOCALE
      );
      if (isWrongPath) {
        navigate(`${pathname.substring(3)}${hash}${search}`);
      }
    } else {
      if (pathname.length < 4) {
        if (pathname.substring(1) !== currentLocale) {
          navigate(`/${currentLocale}${hash}${search}`);
        }
      } else {
        let localePath = locales.find(
          (locale) =>
            pathname.slice(0, 4) === `/${locale}/` && locale !== DEFAULT_LOCALE
        );

        if (localePath) {
          if (localePath !== currentLocale) {
            navigate(
              `/${currentLocale}${pathname.substring(3)}${hash}${search}`
            );
          }
        } else {
          navigate(`/${currentLocale}${pathname}${hash}${search}`);
        }
      }
    }
  }, [pathname]);
}
