import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./state";

const selectDomain = (state) => state.variables || initialState;

export const selectVariables = createSelector(
  [selectDomain],
  (state) => state.variables
);
