// core
import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";

// utils

// components

// styles
import "./index.scss";
import AppButton from "../../../components/atoms/Button";

const HomeCta = ({ data }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <div className="home-cta">
      <div className="app__wrapper">
        <div className="home-cta__wrap">
          <div className="home-cta__left">
            <div className="home-cta__left-title">{data.title}</div>
            <div
              className="home-cta__left-desc"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
          </div>
          <div className="home-cta__right">
            <AppButton
              className="app-button--fill-white"
              size={width > 1024 ? "big" : ""}
              href={"/terms"}
            >
              {t("landing.termsOfUse")}
            </AppButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCta;
