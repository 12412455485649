import { $apiClient } from "../../../utils/api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getBlocks = createAsyncThunk(`blocks/getBlocks`, async () => {
  const url = "landing/blocks";
  return await $apiClient.get(url);
});

export const getTerms = createAsyncThunk(`blocks/getTerms`, async ({ id }) => {
  const url = `landing/activities/${id}`;
  return await $apiClient.get(url);
});

export const getReserves = createAsyncThunk(`blocks/getReserves`, async () => {
  const url = "landing/terms";
  return await $apiClient.get(url);
});

export const getRoadmap = createAsyncThunk(`blocks/getRoadmap`, async () => {
  const url = "roadmaps";
  return await $apiClient.get(url);
});
