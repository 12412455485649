const ArrowUpRight = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.61774 4.07309C5.07109 4.26778 4.83616 4.94319 5.12022 5.50333C5.21647 5.693 5.31267 5.78533 5.54155 5.90755C5.69692 5.99052 5.88905 5.9959 9.14967 6.00837L12.5937 6.02158L8.34601 10.2713C4.88703 13.732 4.08764 14.5547 4.0409 14.7023C3.86359 15.2621 4.28534 15.911 4.87151 15.9804C5.35215 16.0373 5.10995 16.2535 9.72436 11.6486L13.9774 7.40422L13.9907 10.8455C14.0031 14.1036 14.0085 14.2956 14.0915 14.4508C14.214 14.6799 14.3065 14.7759 14.4961 14.871C14.9162 15.0819 15.3908 15.0199 15.6979 14.7139C15.8119 14.6004 15.9132 14.4436 15.9438 14.3335C16.0218 14.0527 16.0171 4.90445 15.9389 4.64324C15.8653 4.39787 15.6024 4.13521 15.3569 4.0617C15.0589 3.97251 5.87001 3.98324 5.61774 4.07309Z"
      fill="#376FE5"
    />
  </svg>
);

export default ArrowUpRight;
