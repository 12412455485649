import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./state";

const selectDomain = (state) => state.news || initialState;

export const selectNews = createSelector(
  [selectDomain],
  (newsState) => newsState.news
);

export const selectArticle = createSelector(
  [selectDomain],
  (newsState) => newsState.article
);
