import { $apiClient } from "../../../utils/api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getVariables = createAsyncThunk(
  `variables/getVariables`,
  async () => {
    const url = "variables";
    return await $apiClient.get(url);
  }
);
