export const initialState = {
  blocks: {
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null,
  },
  reserves: {
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null,
  },
  roadmap: {
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null,
  },
  terms: {
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null,
  },
};
