// components
import Loader from "../Loader";

// styles
import "./index.scss";

const PageLoader = ({ active }) => {
  return (
    <div className="page-loader">
      <Loader active={active} />
    </div>
  );
};

export default PageLoader;
