// core
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

// store
import { selectVariables } from "../../../store/modules/variables/selectors";
import { toggleContactUsModalStatus } from "../../../store/modules/app/slice";
import { getVariables } from "../../../store/modules/variables/services";

// components
import LogoDark from "../../../components/images/LogoDark";

// styles
import "./index.scss";

const Footer = () => {
  // store
  const dispatch = useDispatch();
  const variables = useSelector(selectVariables);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getVariables());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentYear = new Date().getFullYear();

  const openModalHandler = () => {
    dispatch(toggleContactUsModalStatus(true));
  };

  return (
    <footer className="footer">
      {variables.data && (
        <div className="app__wrapper">
          <div className="footer__grid">
            <div className="footer__left">
              <div className="footer__logo">
                <LogoDark />
              </div>
              <div className="footer__copy">
                © {currentYear} UAHg. {t("footer.copy")}
              </div>
            </div>
            <div className="footer__center">
              <ul className="footer__links">
                <li>
                  <a
                    onClick={() => openModalHandler()}
                    title={t("footer.contacts")}
                  >
                    {t("footer.contacts")}
                  </a>
                </li>
                {variables.data.whitepaper &&
                  variables.data.whitepaper.value && (
                    <li>
                      <a
                        href={variables.data.whitepaper.value}
                        target="_blank"
                        title={t("footer.whitepaper")}
                      >
                        {t("footer.whitepaper")}
                      </a>
                    </li>
                  )}
                {variables.data.license && variables.data.license.value && (
                  <li>
                    <a
                      href={variables.data.license.value}
                      target="_blank"
                      title={t("footer.license")}
                    >
                      {t("footer.license")}
                    </a>
                  </li>
                )}
              </ul>
              <ul className="footer__links">
                {variables.data.documents && variables.data.documents.value && (
                  <li>
                    <a
                      href={variables.data.documents.value}
                      target="_blank"
                      title={t("footer.documents")}
                    >
                      {t("footer.documents")}
                    </a>
                  </li>
                )}
                {variables.data.privacy_policy &&
                  variables.data.privacy_policy.value && (
                    <li>
                      <a
                        href={variables.data.privacy_policy.value}
                        target="_blank"
                        title={t("footer.privacy_policy")}
                      >
                        {t("footer.privacy_policy")}
                      </a>
                    </li>
                  )}
                {variables.data.terms_of_use &&
                  variables.data.terms_of_use.value && (
                    <li>
                      <a
                        href={variables.data.terms_of_use.value}
                        target="_blank"
                        title={t("footer.terms_of_use")}
                      >
                        {t("footer.terms_of_use")}
                      </a>
                    </li>
                  )}
              </ul>
            </div>
            <div className="footer__right">
              <ul className="footer__socials">
                {variables.data.medium_url &&
                  variables.data.medium_url.value &&
                  variables.data.medium_title &&
                  variables.data.medium_title.value && (
                    <li>
                      {variables.data.medium_icon &&
                        variables.data.medium_icon.value && (
                          <img src={variables.data.medium_icon.value} />
                        )}
                      <a
                        href={variables.data.medium_url.value}
                        target="_blank"
                        title={variables.data.medium_title.value}
                      >
                        {variables.data.medium_title.value}
                      </a>
                    </li>
                  )}

                {variables.data.instagram_url &&
                  variables.data.instagram_url.value &&
                  variables.data.instagram_title &&
                  variables.data.instagram_title.value && (
                    <li>
                      {variables.data.instagram_icon &&
                        variables.data.instagram_icon.value && (
                          <img src={variables.data.instagram_icon.value} />
                        )}
                      <a
                        href={variables.data.instagram_url.value}
                        target="_blank"
                        title={variables.data.instagram_title.value}
                      >
                        {variables.data.instagram_title.value}
                      </a>
                    </li>
                  )}

                {variables.data.twitter_url &&
                  variables.data.twitter_url.value &&
                  variables.data.twitter_title &&
                  variables.data.twitter_title.value && (
                    <li>
                      {variables.data.twitter_icon &&
                        variables.data.twitter_icon.value && (
                          <img src={variables.data.twitter_icon.value} />
                        )}
                      <a
                        href={variables.data.twitter_url.value}
                        target="_blank"
                        title={variables.data.twitter_title.value}
                      >
                        {variables.data.twitter_title.value}
                      </a>
                    </li>
                  )}

                {variables.data.reddit_url &&
                  variables.data.reddit_url.value &&
                  variables.data.reddit_title &&
                  variables.data.reddit_title.value && (
                    <li>
                      {variables.data.reddit_icon &&
                        variables.data.reddit_icon.value && (
                          <img src={variables.data.reddit_icon.value} />
                        )}
                      <a
                        href={variables.data.reddit_url.value}
                        target="_blank"
                        title={variables.data.reddit_title.value}
                      >
                        {variables.data.reddit_title.value}
                      </a>
                    </li>
                  )}

                {variables.data.telegram_url &&
                  variables.data.telegram_url.value &&
                  variables.data.telegram_title &&
                  variables.data.telegram_title.value && (
                    <li>
                      {variables.data.telegram_icon &&
                        variables.data.telegram_icon.value && (
                          <img src={variables.data.telegram_icon.value} />
                        )}
                      <a
                        href={variables.data.telegram_url.value}
                        target="_blank"
                        title={variables.data.telegram_title.value}
                      >
                        {variables.data.telegram_title.value}
                      </a>
                    </li>
                  )}

                {variables.data.facebook_url &&
                  variables.data.facebook_url.value &&
                  variables.data.facebook_title &&
                  variables.data.facebook_title.value && (
                    <li>
                      {variables.data.facebook_icon &&
                        variables.data.facebook_icon.value && (
                          <img src={variables.data.facebook_icon.value} />
                        )}
                      <a
                        href={variables.data.facebook_url.value}
                        target="_blank"
                        title={variables.data.facebook_title.value}
                      >
                        {variables.data.facebook_title.value}
                      </a>
                    </li>
                  )}

                {variables.data.email_url &&
                  variables.data.email_url.value &&
                  variables.data.email &&
                  variables.data.email.value && (
                    <li>
                      {variables.data.email_icon &&
                        variables.data.email_icon.value && (
                          <img src={variables.data.email_icon.value} />
                        )}
                      <a
                        href={`mailto:${variables.data.email_url.value}`}
                        title={variables.data.email.value}
                      >
                        {variables.data.email.value}
                      </a>
                    </li>
                  )}

                {variables.data.phone_url &&
                  variables.data.phone_url.value &&
                  variables.data.phone &&
                  variables.data.phone.value && (
                    <li>
                      {variables.data.phone_icon &&
                        variables.data.phone_icon.value && (
                          <img src={variables.data.phone_icon.value} />
                        )}
                      <a
                        href={`tel:${variables.data.phone_url.value}`}
                        title={variables.data.phone.value}
                      >
                        {variables.data.phone.value}
                      </a>
                    </li>
                  )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
