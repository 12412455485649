// core
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// store
import { getTerms } from "../../store/modules/blocks/services";
import { selectTerms } from "../../store/modules/blocks/selectors";

// utils

// components
import PageLoader from "../../components/atoms/PageLoader";

// styles
import "./index.scss";

import AOS from "aos";

const ActivityTerms = () => {
  const { id: termsId } = useParams();
  // store
  const dispatch = useDispatch();
  const reserves = useSelector(selectTerms);
  useEffect(() => {
    dispatch(getTerms({ id: termsId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reserves.isSuccess) {
      AOS.init({
        offset: 200,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserves.isSuccess]);
  // @ts-ignore
  return (
    <div className="terms">
      {reserves.isSuccess ? (
        <>
          <div className="app__wrapper">
            <div className="terms__wrap">
              <div className="terms__title">{reserves.data.terms_title}</div>
              <div
                className="terms__content"
                dangerouslySetInnerHTML={{
                  __html: reserves.data.terms_description,
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <PageLoader active={true} />
      )}
    </div>
  );
};

export default ActivityTerms;
