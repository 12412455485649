import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import * as services from "./services";

// Creating slice with reducers
const slice = createSlice({
  name: "news",
  initialState,
  extraReducers: {
    [services.getNews.pending]: (state) => {
      state.news.isLoading = true;
      state.news.isSuccess = false;
      state.news.error = null;
    },
    [services.getNews.rejected]: (state, action) => {
      state.news.isLoading = false;
      state.news.error = action.payload;
    },
    [services.getNews.fulfilled]: (state, action) => {
      state.news.isLoading = false;
      state.news.isSuccess = true;
      state.news.data = action?.payload?.data?.data;
      state.news.meta = action?.payload?.data?.meta;
    },

    [services.getArticle.pending]: (state) => {
      state.article.isLoading = true;
      state.article.isSuccess = false;
      state.article.error = null;
    },
    [services.getArticle.rejected]: (state, action) => {
      state.article.isLoading = false;
      state.article.error = action.payload;
    },
    [services.getArticle.fulfilled]: (state, action) => {
      state.article.isLoading = false;
      state.article.isSuccess = true;
      state.article.data = action?.payload?.data?.data;
    },
  },
});

export default slice.reducer;
