// core

// utils
import { useTranslation } from "react-i18next";
// components

// styles
import "./index.scss";
import AppButton from "../../../components/atoms/Button";
import React, { useState } from "react";
import PromoModal from "../../../components/features/PromoModal";
import ReactDOM from "react-dom";

const HomeServices = ({ data }) => {
  const [isActiveModal, setIsActiveModal] = useState(false);

  const openModalHandler = () => {
    setIsActiveModal(true);
  };

  const closeModalHandler = () => {
    setTimeout(() => setIsActiveModal(false), 400);
  };
  const { t } = useTranslation();
  return (
    <div className="home-main">
      <div className="app__wrapper">
        <div className="home-main__wrap">
          <div className="home-main__content">
            <div className="home-main__content-title">{data.title}</div>
            <div
              className="home-main__content-desc"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
            <div className="home-main__content-btn">
              <AppButton
                onClick={openModalHandler}
                size={"big"}
                className="app-button--fill"
              >
                {t("landing.details")}
              </AppButton>
            </div>
          </div>
          <div className="home-main__banner">
            <img src={data.image} alt={data.title} />
          </div>
        </div>
      </div>
      {isActiveModal &&
        ReactDOM.createPortal(
          <PromoModal
            isActive={isActiveModal}
            data={data.popup}
            onClose={closeModalHandler}
          />,
          document.getElementById("modal")
        )}
    </div>
  );
};

export default HomeServices;
