import React, { useState } from "react";
import ClickAwayListener from "react-click-away-listener";

// locale
import { useTranslation } from "react-i18next";

// components
import AppButton from "../Button";

// styles
import "./index.scss";

//data
import { languagesList } from "../../../locales/data";

const LangToggler = () => {
  // translation hook
  const { i18n } = useTranslation();

  let [isOpen, setIsOpen] = useState(false);

  const onChangeLangHandler = (language) => {
    onCloseLangListHandler();
    i18n.changeLanguage(language);
    window.location.reload();
  };

  const localization =
    i18n.language && i18n.language.length > 2
      ? i18n.language.slice(0, 2)
      : i18n.language;

  // current locale language
  const currentLanguage = languagesList.find(
    (lang) => lang.id === localization
  );

  const onToggleLangListHandler = (flag) => {
    setIsOpen(flag);
  };

  const onCloseLangListHandler = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={onCloseLangListHandler}>
      <div className="lang-toggler">
        <AppButton
          className={
            "lang-toggler__btn app-button--flat" +
            (isOpen ? " lang-toggler__btn--active" : "")
          }
          onClick={() => onToggleLangListHandler(!isOpen)}
          append={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 9L12 16L5 9"
                stroke="currentColor"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        >
          <span>{currentLanguage?.short}</span>
        </AppButton>
        <div
          className={
            "lang-toggler__menu-list" +
            (isOpen ? " lang-toggler__menu-list--active" : "")
          }
        >
          <ul>
            {languagesList.map((lang) => (
              <li key={lang.id}>
                <button
                  onClick={() => onChangeLangHandler(lang.id)}
                  className={
                    lang.id === currentLanguage?.id
                      ? " lang-toggler__menu-list--current"
                      : ""
                  }
                >
                  {lang.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default LangToggler;
