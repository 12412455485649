export const DEFAULT_LOCALE = "uk";

export const languagesList = [
  {
    id: "en",
    short: "EN",
    label: "English",
  },
  {
    id: "uk",
    short: "UA",
    label: "Українська",
  },
];
