export const initialState = {
  news: {
    meta: null,
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null,
  },
  article: {
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null,
  },
};
