import { $apiClient } from "../../../utils/api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const sendContact = createAsyncThunk(
  `contact/sendContact`,
  async (payload) => {
    const url = "contact";
    return await $apiClient.post(url, payload);
  }
);
