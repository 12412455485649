// core
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// store
import { getReserves } from "../../store/modules/blocks/services";
import { selectReserves } from "../../store/modules/blocks/selectors";

// utils

// components
import PageLoader from "../../components/atoms/PageLoader";

// styles
import "./index.scss";

import AOS from "aos";

const Terms = () => {
  // store
  const dispatch = useDispatch();
  const reserves = useSelector(selectReserves);
  useEffect(() => {
    dispatch(getReserves());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reserves.isSuccess) {
      AOS.init({
        offset: 200,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserves.isSuccess]);
  // @ts-ignore
  return (
    <div className="terms">
      {reserves.isSuccess ? (
        <>
          <div className="app__wrapper">
            <div className="terms__wrap">
              <div className="terms__title">{reserves.data.title}</div>
              <div
                className="terms__content"
                dangerouslySetInnerHTML={{
                  __html: reserves.data.description,
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <PageLoader active={true} />
      )}
    </div>
  );
};

export default Terms;
