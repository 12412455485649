import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./state";

const selectDomain = (state) => state.app || initialState;

export const selectContactUsModal = createSelector(
  [selectDomain],
  (state) => state.contactUsModal
);
